import { gql } from '@apollo/client';
import { INQUIRY_MSG_STATS_FRAGMENT } from 'core/queries/inquiry';

const INQUIRIES_LIST_BASE_INQUIRY_FRAGMENT = gql`
  fragment InquiriesListBaseInquiry on InquiryDto {
    id
    archivedAt
    dealerId
    conversationType
    conversationCompleted
    source
    isBlocked
    isNew
    stopped
    department
    type
    crmNotificationSent
    customer {
      id
      name
      firstName
      lastName
      phoneNumber
      contactPhoneNumber
    }
    assigned {
      id
      name
    }
    phoneNumber {
      dealerName
      dealerId
    }
    inquiryStatus {
      statusId
      statusName
    }
  }
`;

const INQUIRIES_LIST_INQUIRY_FRAGMENT = gql`
  fragment InquiriesListInquiry on InquiryDto {
    ...InquiriesListBaseInquiry
    msgStats {
      ...InquiryMsgStats
    }
  }
  ${INQUIRIES_LIST_BASE_INQUIRY_FRAGMENT}
  ${INQUIRY_MSG_STATS_FRAGMENT}
`;

const INQUIRIES_LIST_COMPLETED_INQUIRY_FRAGMENT = gql`
  fragment InquiriesListCompletedInquiry on InquiryDto {
    ...InquiriesListBaseInquiry
    msgStats {
      lastMsgText
    }
  }
  ${INQUIRIES_LIST_BASE_INQUIRY_FRAGMENT}
`;

const INQUIRIES_LIST_QUERY = gql`
  query InquiriesListQuery(
    $dealerIds: [Long]!
    $userId: Long!
    $pagination: PaginationInput!
    $filters: FiltersDtoInput
    $searchType: SearchTypeInput = DEFAULT
    $isCompleted: Boolean = false
    $isHandedOff: Boolean = false
  ) {
    searchInquiries(
      dealerIds: $dealerIds
      userId: $userId
      pagination: $pagination
      filters: $filters
      searchType: $searchType
      isHandedOff: $isHandedOff
    ) @connection(key: "inquiries", filter: ["dealerId", "userId", "filters", "searchType"]) {
      list {
        ...InquiriesListInquiry @skip(if: $isCompleted)
        ...InquiriesListCompletedInquiry @include(if: $isCompleted)
      }
      total
      page
      pageSize
    }
  }
  ${INQUIRIES_LIST_INQUIRY_FRAGMENT}
  ${INQUIRIES_LIST_COMPLETED_INQUIRY_FRAGMENT}
`;

export { INQUIRIES_LIST_QUERY, INQUIRIES_LIST_INQUIRY_FRAGMENT };
